<template>
  <div class="table-expand-card__content">
    <div
      class="table-expand-card sub"
      :class="{ 'expanded': expandedItems.includes(item.name), 'parent-card': isParent }"
    >
      <h2>
        {{ item.name }}
        <span
          v-if="isParent"
          class="parent-title"
        >
          Root
        </span>
      </h2>
      <div
        v-for="(field) in fields"
        :key="field.field"
        class="table-expand-card__field"
      >
        <div
          v-if="!field.hidden && field.field !== 'action'"
          class="table-expand-card__field__item"
        >
          <p>{{ field.label }}: <span>{{ item[field.field] || '-' }}</span></p>
        </div>
        <div v-if="field.field === 'action'">
          <div
            class="table-expand-card__field__item__button-container"
          >
            <div
              v-for="(action) in actions"
              :key="action.label"
            >
              <b-button
                v-if="action.visible(item)"
                class="w-100"
                :variant="action.type"
                @click="action.click(item.id)"
              >
                {{ action.label }}
              </b-button>

            </div>
          </div>
        </div>
      </div>
      <button
        class="table-expand-card__expand"
        :class="{ 'expanded': expandedItems.includes(item.name) }"
        @click="expand(item.name)"
      >
        <feather-icon icon="ChevronDownIcon" />
      </button>
    </div>
  </div>
</template>

<script>

import {
  // BDropdown,
  // BDropdownItem,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    // BDropdown,
    // BDropdownItem,
    BButton,
  },
  props: {
    isParent: {
      type: Boolean,
      default: false,
    },
    expandedItems: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    expand: {
      type: Function,
      default: () => {},
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
</style>
