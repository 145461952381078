<template>
  <div>
    <div
      v-for="(item) in items"
      :key="item[keyValue]"
      class="table-expand-card"
      :class="{ 'expanded': expandedItems.includes(`${item[keyValue]}-card`) }"
    >
      <h2>
        {{ item[keyValue] }}
      </h2>
      <div class="table-expand-card__content">
        <table-mobile-expand
          :item="item"
          :fields="fields"
          :actions="actions"
          :is-parent="true"
          :expanded-items="expandedItems"
          :expand="expand"
        />
        <table-mobile-expand
          v-for="(end) in item.ends"
          :key="end.keyValue"
          :item="end"
          :fields="fields"
          :actions="actions"
          :expanded-items="expandedItems"
          :expand="expand"
        />
      </div>
      <button
        class="table-expand-card__expand"
        :class="{ 'expanded': expandedItems.includes(`${item[keyValue]}-card`) }"
        @click="expand(`${item[keyValue]}-card`)"
      >
        <feather-icon icon="ChevronDownIcon" />
      </button>
    </div>
    <div
      v-if="pagination.enabled"
      class="justify-content-center flex-wrap table-pagination"
    >
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap">
          Showing 1 to
        </span>
        <b-form-select
          v-model="pagination.perPage"
          :options="pagination.pageSizeOptions"
          class="mx-1"
          @change="pagination.perPageChanged"
        />
        <span class="text-nowrap"> of {{ pagination.pagination.total }} root entries.</span>
      </div>
      <div>
        <b-pagination
          v-if="pagination.pagination.last_page !== 1"
          :value="page"
          :total-rows="pagination.pagination.total"
          :per-page="pagination.perPage"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="pagination.changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>

import { BFormSelect, BPagination } from 'bootstrap-vue'
import TableMobileExpand from './TableMobileExpand.vue'

export default {
  components: {
    TableMobileExpand,
    BFormSelect,
    BPagination,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({
        enabled: false,
      }),
    },
    actions: {
      type: Array,
      default: () => [],
    },
    pageSizeOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    keyValue: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      expandedItems: [],
    }
  },
  methods: {
    expand(name) {
      if (this.expandedItems.includes(name)) {
        this.expandedItems = this.expandedItems.filter(item => item !== name)
      } else {
        this.expandedItems.push(name)
      }
    },
  },

}
</script>

<style lang="scss">
</style>
